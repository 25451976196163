
.site-first-screen {
    width: 100%;
    height: 100%;
    position: relative;
}
.site-video {
    object-fit: cover;
}
.site-text {
    position: absolute;
    z-index: 1;
    top: 45.4%;
    left: 40px;
    right: 40px;
    display: flex;
    justify-content: space-between;
}
.text-left {
    
}
.text-right {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
}
