.contactUs {
    font-size: 16px;
    color:#E8F0F9;
    background: #101012;
    width:100%;
    .title {
        font-size: 32px;
        text-align: left;
        line-height: 48px;
        color: #fff;
        padding: 0 40px;
        padding-bottom: 40px;
        margin-top: 8px;
    }
    .line {
        opacity: 0.2;
        height: 1px;
        border-bottom: 1px solid #FFFFFF;

    }
    .contentLayout {
        display: flex;
        text-align: left;
        padding: 40px 40px 0 40px;
        justify-content: space-around;
    }
    .leaveMessage {
        width:35%;
    }
    .messageMode {
        display: flex;
        gap: 14px;
        .itemInput {
            padding-left: 16px;
            height: 40px;
            line-height: 40px;
            border: 1px solid #ffffff30;
            background: #101012;
            color: #fff;
            &::placeholder {
                font-weight: 500;
                font-size: 16px;
                color: #ffffff30;
            }
            &:hover {
                border: 1px solid #777;
                &::placeholder {
                    opacity: 0.5;
                    color: #fff;
                }
            }
            &:focus-visible,
            &:focus{
                outline: none;
                border: 1px solid #777;
            }
        }
    }
    .textDiv {
        width:83%;
        color: #FFF;
        position: relative;
    }
    .inputInfo {
        color:#fff;
        font-size: 16px;
        width: 100%;
        &::placeholder {
            color: #ffffff30;

        }
        &:focus-visible,
        &:focus{
            outline: none;
            border: 1px solid #777;
        }
        &:hover {
            border: 1px solid #777;
            &::placeholder {
                opacity: 0.5;
                color: #fff;
            }
        }
        padding: 16px;
        background: #000;
        border: 1px solid #444;
        margin-top: 24px;
        height: 194px;  
        width:100%;
    }
    .textLength {
        position: absolute;
        opacity: 0.2;
        bottom: 26%;;
        right: 10px;
        z-index: 2;
    }
    .inputBtn {
        width: 70px;
        height: 40px;
        padding: 0px 14px;
        line-height: 40px;
        background: #25252C;
        text-align: center;
        margin-top: 16px;
        margin-bottom: 40px;
        cursor: pointer;
        &:hover {
            background: #311EA6;
        }
    }
    .contactInfo {
        width:25%;
        display: flex;
        // 主轴方向，从上到下
        flex-direction: column;
        gap: 40px;
        .info {
            height: 40px;
            font-size: 20px;
            color: #FFF;
            padding-top: 16px;
            padding-left: 52px;
        }
    }
    .address {
        flex: 1; 
        padding-left: 12px;
    }
    .addressImg {
        width: 328px;
        height: 194px;
        padding-top: 26px;
        .adImg {
            opacity: 0.8;
            width:100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .addressBox {
        display: flex;
        // 主轴方向，从上到下
        flex-direction: column;
        gap: 16px;
    }
    .contentLayoutMedia {
        // display: flex;
        // flex-wrap: wrap;
        padding: 38px 40px;
        .middleMedia {
            display: flex;
            margin-bottom: 40px;
            gap: 80px;
        }
        .info {
            display: inline-block;
            padding: 0px;
            margin-left: 8px;
        }
        .textDiv {
            width: 81.5%;
        }
    }
    
}