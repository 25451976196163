.companyProfile {
    width:100%;
    color: #E8E8EF;
    padding-bottom: 70px;
    background: #000;
    .title {
      font-size: 32px;
      padding-bottom: 40px;
      padding-left: 40px;
      margin-top: 10px;
      line-height: 48px;
    }
    .itemTitle {
      color: #E8E8EF;
        height: 80px;
        display: flex;
        cursor: pointer;
        font-size: 28px;
        font-weight: 500;
        line-height: 80px;
        border-bottom: 1px solid #444;
        .titleLeft {
            width:40%;
            height: 100%;
            padding-left: 40px;
            span {
                height: 100%;
                display: inline-block;
            }
        }
    }
    .itemContent {
        min-height: 120px;
        height: 150px;
        display: flex;
        font-size: 18px;
        text-align: left;
        position: relative;
        padding: 40px 0px;
        border-bottom: 1px solid #444;
        transition: all 3.5s;
        // animation: wrapper-gradient 2s linear;
        // transition-delay: 2.8s;
        .itemImg {
            padding-left: 40px;
            line-height: 353px;
            width:40%;
            overflow: hidden;
            .img {
                transition: all 3s;
                width:80%;
                // animation: img-gradient 2s linear;
            }
        }
        .itemLeft {
            width:40%;
            padding:0 40px;
            line-height: 28px;
        }
        .content {
          padding-left: 60px;
            // animation: text-gradient 2s linear;
            flex:1;
            left: 40%;
            bottom: 40px;
            right: 40px;
            line-height: 30px;
            padding-right: 15%;
            display: flex;
            align-items: flex-end;
        }
        
    }
}
.css-dev-only-do-not-override-k83k30 {
    .ant-collapse-item {
        .ant-collapse-header {
            padding: 0px !important;
            display: block !important
        }
    }
}
@keyframes wrapper-gradient {
    0% {
      transform: translateY(30%);
      opacity: 0.4;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes img-gradient {
    0% {
      transform: translateY(-30%);
      opacity: 0.2;

    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes text-gradient {
    0% {
      transform: translateY(30%);
      opacity: 0.2;

    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
 
