.siteServices {
    width: 100%;
    height: 725px;
    padding: 60px 40px 0px 40px;
    margin-bottom: 100px;
    // background-color: #131314;
    background-color: #49494f33;
    .servicesTitle {
        font-family: 'Roboto Mono';
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: #FFCC49;
    }
    .servicesCN {
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;
        display: flex;
        align-items: center;
        color: #E8E8EF;
        padding-top: 10px;
        padding-bottom: 40px;
    }
    .servicesBox {
        display: flex;
        gap: 40px;
        height: 380px;
    }
    .boxLeft, .boxRight {
        width: 50%;
        height: 100%;
    }
    .boxImg {
        width: 100%;
        // height:
        object-fit: cover;
    }
    .boxInfo {
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.04em;
        color: #E8E8EF;
        padding-top: 30px;
    }
}