.headerBar {
    padding-left: 40px;
    z-index: 8;
    width:100%;
    position: fixed;
    top: 0px;
    font-weight: 500;
    font-size: 18px;
    vertical-align: middle;
}
