.siteIntroductionInfo {
    // background: #49494f33;
    background: #1D1D21;
    
    width: 100%;
    .infoBox {
        width: 100%;
        color: #E8E8EF;
        height: 403px;
        width: 664px;
        margin: 0 auto;
    }
    .siteTitle {
        text-align: center;
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        margin-bottom: 24px;
        padding-top: 80px;
    }
    .siteDesc {
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.04em;
        font-family: 'PingFang SC';
        text-align: center;
    }
}