.siteBannerLeft {
    width:100%;
    display: flex;
    // height: 560px;
    height: 89%;
    overflow: hidden;
    border: 1px solid #342b2b;
    .banner {
        height: 100%;
        width: 80%;
       overflow: hidden;
       position: relative;
    }
    .content {
        opacity: 1;
        flex: 1;
        min-width: 384px;
        display: flex;
        color: #FFFF;
        padding: 40px;
        // 主轴方向，从上到下
        flex-direction: column;
        justify-content: space-between;
        border-left: 1px solid #342b2b;;
        margin-right: 1px;
        .picDesc {
            font-size: 28px;
            line-height: 40px;
            letter-spacing: 0.02em;
        }
        .title {
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            color: #FFCC49;
        }
        .titleCN {
            padding-top: 10px;
            font-size: 32px;
            line-height: 48px;
            color: #E8E8EF;
        }
        .desc {
            font-family: 'PingFang SC';
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            letter-spacing: 0.04em;
            color: #E8E8EF;
        }
    }
   
}