.site-main-banner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

    .site-main-banner-offset {
        position: relative;
        height: 100%;
    }

    .site-main-banner-content-item {
        justify-content: center;
        position: relative;
        display: flex;
        flex: 1 0 auto;
        opacity: 1;
        width: 100%;
        height: 100%;
    }

    .site-video {
        object-fit: cover;
    }
    
    .site-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }


    .site-main-banner-content-scroller {
        position: relative;
        display: flex;
        height: 100%;
    }

    .site-controller {
        /* position: absolute;
        top: 48%;
        z-index: 6;
        display: flex; */
        /* width:100%;
        padding: 0px 30px;
        justify-content: space-between; */
    }
    .site-controller-next {
        position: absolute;
        top: 48%;
        z-index: 6;
        right:20px;
        width: 18px;
        height: 18px;
        border: 1px solid #fff;
        opacity: 0.3;
        border-bottom: none;
        border-left: none;
        transform:rotate(45deg);
        -webkit-transform:rotate(45deg);
        -moz-transform:rotate(45deg);
        -ms-transform:rotate(45deg);
        -o-transform:rotate(45deg);
    }
    .site-controller-pre {
        position: absolute;
        top: 48%;
        z-index: 6;
        left: 20px;
        width: 18px;
        height: 18px;
        border: 1px solid #fff;
        opacity: 0.3;
        border-bottom: none;
        border-right: none;
        transform:rotate(-45deg);
        -webkit-transform:rotate(-45deg);
        -moz-transform:rotate(-45deg);
        -ms-transform:rotate(-45deg);
        -o-transform:rotate(-45deg);
    }
    .site-controller-next:hover{
        cursor: pointer;
        border: 1px solid #fff;
        border-bottom: none;
        border-left: none;
        opacity: 1;
    }
    .site-controller-pre:hover {
        cursor: pointer;
        border: 1px solid #fff;
        border-bottom: none;
        border-right: none;
        opacity: 1;
    }
