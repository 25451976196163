.pageFooter {
    height: 64px;
    background: #1d1d21;
    // width:100%;
    line-height: 64px;
    font-size: 16px;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    .logo {
        padding-right: 6px;
    }
}